import BaseController from '../../base_controller'

export default class EditModalController extends BaseController {
  static targets = ['formTab', 'lcsidsTab', 'form', 'lcsids']

  showForm () {
    this.formTabTarget.classList.add('selected')
    this.formTarget.hidden = false

    this.lcsidsTabTarget.classList.remove('selected')
    this.lcsidsTarget.hidden = true
  }

  showLcsids () {
    this.lcsidsTabTarget.classList.add('selected')
    this.lcsidsTarget.hidden = false

    this.formTabTarget.classList.remove('selected')
    this.formTarget.hidden = true
  }
}
